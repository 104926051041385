.month-container {
  margin-bottom: 5rem;
}

.month-heading {
  font-size: 5rem;
  font-weight: 400;
  color: #999;
  margin-bottom: 1rem !important;
}

.ui.four.doubling.link.cards.markedsdager {
  align-items: flex-start;
}

.date-and-title {
  display: flex;
  align-items: center;
  flex-grow: 0 !important;
}

.date-and-title .date {
  flex: 0 0 50px;
  text-align: center;
  padding: 3px 10px 0 0;
}

.date-and-title .date .month {
  color: #fa3e3e;
  font-size: 12px;
  line-height: 12px;
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
}

.date-and-title .date .day {
  font-size: 24px;
  line-height: 28px;
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
  color: #333;
}

.date-and-title .title {
  flex: 1 1 auto;
  margin-top: 0;
  overflow: hidden;
  font-size: 1.1em !important;
  padding: 2px 0;
}

.date-and-title .title .markedsplass {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* Mobile */
@media only screen and (max-width: 767px) {
  .month-heading {
    font-size: 3rem;
  }

  .ui.header.markedsdag {
    font-size: 1.5rem;
  }

  .month-container {
    margin-bottom: 3rem;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Small Monitor */
@media only screen and (min-width: 992px) and (max-width: 1399px) {
}

/* Large Monitor */
@media only screen and (min-width: 1400px) {
}
