.frontpage-news-header {
  padding-bottom: 0 !important;
}

.ui.items>.item.news>.image:not(.ui) {
  width: 150px;
}

.content.news {
  color: #000;
}

.header.news {
  font-size: 2.25rem !important;
  line-height: 1.25;
}

.ui.items a.item:hover .content .header.news, .ui.link.items>.item:hover .content .header.news {
  color: #333;
}

.image.news-article img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.heading.news-article {
  font-size: 3rem !important;
  line-height: 1.25;
}

p.lead.news-article {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 0.5em;
}

.published.news-article {
  color: #999;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .header.news {
    font-size: 2.25rem !important;
    line-height: 1.2;
  }

  .heading.news-article {
    font-size: 2.25rem !important;
    line-height: 1.2;
    margin-top: 0;
  }

  p.lead.news-article {
    font-size: 1.45rem;
    line-height: 1.35;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Small Monitor */
@media only screen and (min-width: 992px) and (max-width: 1399px) {
}

/* Large Monitor */
@media only screen and (min-width: 1400px) {
}
