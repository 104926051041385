.letter-container {
  margin-bottom: 5rem;
}

.letter-heading  {
  font-size: 5rem;
  font-weight: 400;
  color: #999;
  margin-bottom: 1rem !important;
}

.produktinfo {
  font-size: 1.15rem;
  line-height: 1.5;
}

.produktinfo .labels a {
  display: block;
  float: left;
  margin-top: 20px;
  margin-right: 20px;
}

.produktinfo .labels a .ui.mini.image {
  width: 50px;
}

.ui.mini.image.okologisk-icon-card {
  width: 25px;
  margin-right: 10px;
}

.produsent-bildetekst {
  font-size: 1rem;
  text-align: center;
  padding: 0.5rem;
  color: #666;
}

.produsent-fotograf {
  font-size: 0.8rem;
  text-align: center;
  color: #666;
}

.tilstede-markedsdag a {
  white-space: nowrap;
  overflow: hidden;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .letter-heading  {
    font-size: 3rem;
  }

  .ui.contact.button {
    padding: 0.78571429em 1em;
  }

  .letter-container {
    margin-bottom: 3rem;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Small Monitor */
@media only screen and (min-width: 992px) and (max-width: 1399px) {
}

/* Large Monitor */
@media only screen and (min-width: 1400px) {
}
