.campaignCard.content {
  position: absolute;
  bottom: 0;
  border: 0 !important;
  text-align: center;
  padding: 2rem !important;
  text-shadow: 1px 1px 15px #000;
}

.campaignCard.header {
  color: #fff !important;
  font-size: 2rem !important;
}

.campaignCard.text {
  color: #fff;
  font-size: 1.25rem !important;
  line-height: 1.25;
}

h1.heading.campaign {
  font-size: 4rem;
}

p.lead.campaign {
  font-size: 1.7rem;
  line-height: 1.5;
}

.campaign.hero-image {
  min-height: 500px;
  background-size: cover;
  background-position: 50% 50%;
  width: calc(50% - 30px);
  float: left;
  margin-right: 30px;
  margin-bottom: 20px;
}

.album-slider {
  background-color: #333;
}

.album-slider-li {
  position: relative;
}

.album-slider-fotograf {
  font-size: .8rem;
  position: absolute!important;
  z-index: 100;
  bottom: 10px;
  right: 10px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 3px 10px 2px;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .campaign.hero-image {
    min-height: 300px;
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
  }
  h1.heading.campaign {
    font-size: 2.5rem;
    margin-top: 0;
    margin-left: 1rem!important;
    margin-right: 1rem!important;
  }

  p.lead.campaign {
    font-size: 1.25rem;
    margin-left: 1rem!important;
    margin-right: 1rem!important;
  }
  .ui.grid.campaign {
    margin-bottom: 0;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Small Monitor */
@media only screen and (min-width: 992px) and (max-width: 1399px) {
}

/* Large Monitor */
@media only screen and (min-width: 1400px) {
}
