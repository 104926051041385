header.ui.segment.frontpage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 150;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&amp;0.65+0,0+100;Neutral+Density */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(0, 0, 0, 0.65)),
        color-stop(100%, rgba(0, 0, 0, 0))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="#a6000000",
      endColorstr="#00000000",
      GradientType=0
    ); /* IE6-9 */
    padding-bottom: 100px;
}

header .ui.top.menu {
    margin-bottom: 0;
}

header .hamburger.menu span {
    font-size: 16px;
}

header .hamburger.menu > i.icon {
    font-size: 22px;
    margin-right: 10px !important;
}

header .dropdown i.icon {
    font-size: 22px;
    margin-left: 5px !important;
}

header .ui.bottom.menu {
    margin-top: -30px;
}

header svg.ui.logo {
    margin: 0 20px;
    width: 120px;
    transition: all 300ms ease;
}

header #home-link {
    height: 70px;
}

header.frontpage svg.ui.logo {
    fill: #fff;
    width: 150px;
}

header.frontpage #home-link {
    height: 120px;
}
.search-wrapper {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    padding: 15px;
    z-index: 100;
}

.search-wrapper .main.search.form label {
    font-size: 18px;
    color: #fff;
    text-shadow: 0 0 10px #000;
}

.hero {
    height: 500px;
    position: relative;
}

.frontpage-slider {
    position: absolute;
    top: 0;
    border: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    margin-bottom: 0;
    background-color: #ddd;
}

.frontpage-slider-li {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.frontpage-slider-image {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.frontpage-slider-overlay {
    position: absolute !important;
    z-index: 100;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0);
}

h1.frontpage-slider-heading {
    font-size: 3rem;
    color: rgba(0, 0, 0, 0.8);
    background-color: #fff;
    padding: 0 0 5px 0;
    display: inline;
    box-shadow: 20px 0 0 #fff, -20px 0 0 #fff;
}

.frontpage-slider-overlay .ui.button {
    background-color: #fff;
    margin-top: 20px;
}

.frontpage-slider-fotograf {
    font-size: 0.8rem;
    position: absolute !important;
    z-index: 100;
    bottom: 5px;
    right: 20px;
    color: #fff;
    text-shadow: 0 0 1px #000;
}

.promoarea {
    display: flex !important;
    flex: 1;
    justify-content: stretch;
    gap: 1em;
}

.promobox-sms .header {
    font-size: 2.5rem !important;
}

.promobox-sms .content {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
    margin: auto !important;
    line-height: 1.5;
    font-size: 1.25rem !important;
    color: rgba(0, 0, 0, 0.87);
}

.promobox-sms .content p {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}

.nextMarket {
    margin-top: 0 !important;
}

.nextMarket .ui.text.container {
    font-size: 1rem;
}

/* Mobile */
@media only screen and (max-width: 767px) {
    .promoarea {
        display: block !important;
    }

    header.ui.segment.frontpage {
        padding-bottom: 30px;
    }

    header .ui.logo {
        margin: 0;
    }

    header svg.ui.logo {
        width: 90px;
        height: 90px !important;
        margin: 0;
    }

    header #home-link {
        height: 90px !important;
    }

    header.frontpage svg.ui.logo {
        width: 100px;
        height: 100px !important;
    }

    header.frontpage #home-link {
        height: 100px;
    }

    header .ui.right.dropdown {
        padding-right: 10px !important;
    }

    header .ui.right.dropdown i.icon {
        margin-right: 0 !important;
    }

    .search-wrapper {
        bottom: 10px;
    }

    header .ui.huge.button {
        font-size: 1.3rem;
    }

    header .ui.menu > .ui.container {
        width: 100% !important;
    }

    header .home-link {
        position: absolute !important;
        top: 0;
        left: 50%;
        padding: 0 !important;
        transform: translateX(-50%);
    }

    header .ui.bottom.menu {
        margin-top: 30px;
    }

    header.frontpage .ui.bottom.menu {
        margin-top: 50px;
    }

    .hero {
        height: 400px;
    }

    h1.frontpage-slider-heading {
        font-size: 1.75rem;
        line-height: 1.4;
        padding: 0;
        box-shadow: 10px 0 0 #fff, -10px 0 0 #fff;
    }

    .frontpage-slider-overlay.ui.text.container {
        width: 100% !important;
        margin: 0 !important;
        padding-left: 1em;
        padding-right: 1em;
    }

    .lokallag-velger .content {
        font-size: 21px;
        letter-spacing: 1px;
    }

    .ui.category.search > .results .category > .name {
        display: none;
    }

    .ui.category.search > .results .category .result {
        margin-left: 0;
    }

    .promobox-sms .header {
        font-size: 2rem !important;
        line-height: 1.4;
    }

    header .etikett {
        max-width: 60px;
        text-align: right;
    }

    header .hamburger.menu span,
    header .etikett {
        font-size: 13px;
    }
    .search-wrapper .main.search.form label {
        font-size: 16px;
    }
}

@media only screen and (max-width: 375px) {
    header.frontpage svg.ui.logo {
        width: 80px;
        height: 100px !important;
    }
}

@media only screen and (max-width: 320px) {
    header .etikett {
        max-width: 120px;
    }
    header.frontpage svg.ui.logo {
        margin-top: 50px;
        height: 80px;
    }
    header.frontpage .ui.bottom.menu {
        margin-top: 90px;
    }
    .search-wrapper .main.search.form label {
        font-size: 13px;
    }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Small Monitor */
@media only screen and (min-width: 992px) and (max-width: 1399px) {
}

/* Large Monitor */
@media only screen and (min-width: 1400px) {
}
