.footer-home-link {
  display: inline-block;
  margin-top: 10px;
}

.footer-home-link svg {
  fill: #fff;
  width: 200px;
  height: 60px;
}

.fb-page {
  display: block !important;
  margin-top: 40px;
  margin-bottom: 20px;
}

.ui.relaxed.list > .item:first-child {
  padding-top: 0;
}

footer.ui.basic.segment {
  background-color: #569091;
  color: #ddd;
  margin-top: 50px;
}

footer .ui.list .list > .item .header,
footer .ui.list > .item .header,
footer .ui.header {
  color: #fff;
}

footer .ui.list .list > .item {
  padding: 0.3em 0;
}

footer a {
  color: #ddd !important;
}

.footer-social {
  margin-bottom: 20px;
}

.footer-social .ui.facebook.button,
.footer-social .ui.instagram.button {
  color: #fff !important;
}

footer .tiny {
  font-size: 1rem;
}

/* Mobile */
@media only screen and (max-width: 767px) {
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Small Monitor */
@media only screen and (min-width: 992px) and (max-width: 1399px) {
}

/* Large Monitor */
@media only screen and (min-width: 1400px) {
}
