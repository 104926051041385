.ui.cards.cases a.ui.card,
.ui.cards.cases a.ui.card:hover,
.ui.cards.cases a.ui.card .content,
.ui.cards.cases a.ui.card .content:hover {
  box-shadow: none;
  background: #f8f8f9;
}


.ui.cards.cases a.ui.card .image {
  box-shadow: 0 1px 3px 0 #D4D4D5;
}


.content.case-card {
  color: #333;
}

h1.heading.case-card {
  margin-bottom: 0.5rem;
}

p.lead.case-card {
  margin-bottom: 1em !important;
}

h1.heading.case {
  font-size: 4rem;
}

p.lead.case {
  font-size: 1.7rem;
  line-height: 1.5;
}

.case.hero-image {
  min-height: 500px;
  background-size: cover;
  background-position: 50% 50%;
  width: calc(50% - 30px);
  float: left;
  margin-right: 30px;
  margin-bottom: 20px;
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .case.hero-image {
    min-height: 300px;
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
  }
  h1.heading.case {
    font-size: 2.5rem;
    margin-top: 0;
    margin-left: 1rem!important;
    margin-right: 1rem!important;
  }

  p.lead.case {
    font-size: 1.25rem;
    margin-left: 1rem!important;
    margin-right: 1rem!important;
  }
  .ui.grid.case {
    margin-bottom: 0;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Small Monitor */
@media only screen and (min-width: 992px) and (max-width: 1399px) {
}

/* Large Monitor */
@media only screen and (min-width: 1400px) {
}
